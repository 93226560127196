@import '../../scss';

.signup {
  &_input {
    &_fullWidth {
      display: flex;
      height: 66px;
    }
    &_halfWidth {
      width: 50%;
      position: relative;
      &:not(:last-child) {
        margin-right: 2%;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .signup {
    &_input {
      &_fullWidth {
        display: block;
        height: auto;
      }
      &_halfWidth {
        width: 100%;
      }
    }
  }
}
