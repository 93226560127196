@import '../../scss';

.title {
  @include text('Roboto', 3.4rem, 500, normal, normal, normal, normal);
  text-align: center;
  max-width: 100%;
  word-break: break-word;
  padding: 0 0 0.5rem 0;
  &_container {
    color: $color-grey-primary;
    width: 100%;
    &.white {
      color: $color-white-primary;
    }
  }
}
.description {
  @include text('Roboto', 1.6rem, 500, normal, normal, 1.38, normal);
  text-align: center;
  max-width: 100%;
  word-break: break-word;
}
.link {
  color: $color-cerulean-primary;
  border-bottom: 2px solid $color-darkskyblue-ternary;
  padding-bottom: 2px;
}

@media only screen and (max-width: 768px) {
  .title {
    font-size: 2.8rem;
  }
  .description {
    font-size: 1.3rem;
    line-height: 1.31;
  }
}
