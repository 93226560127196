@import '../../scss';

.signup_container {
  @include containerHeight;
  @include flexCenter;
  overflow-y: hidden;
  flex: 1;
  .signup {
    // width: 50%;
    width: 100%;
    margin-right: 50%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
    // justify-content: center;
    // align-content: center;
    overflow: auto;
    margin: auto 50% auto auto;
    padding: 3rem 0;
    &.success {
      width: 100%;
      margin-right: 0;
      justify-content: center;
      align-items: center;
    }
    &_title {
      width: 75%;
      margin: auto auto 0 auto;
    }
    &_input {
      width: 340px;
      margin: 0 auto auto auto;
      &_forgot {
        @include text('Roboto', 1.2rem, normal, normal, normal, normal, normal);
        text-align: center;
        color: $color-grey-secondary;
        margin: 1rem 0 0 0;
        cursor: pointer;
      }
    }
    &_carousel {
      &.success {
        display: none;
      }
      width: 50%;
      // position: relative;
      height: calc(100% - 76px);
      position: absolute;
      top: 76px;
      bottom: 0;
      right: 0;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: top;
      overflow: hidden;
      &.create {
        //  background-image: url($image-path+'/Supergirl.jpg');
      }
      &.addchild_profile {
        background-image: url($image-path + '/add-child-profile.jpg');
      }
      &.addchild_school {
        // background-position: center;
        background-image: url($image-path + '/add-child-school.jpg');
      }
      &.addchild_physical {
        // background-position: center;
        background-image: url($image-path + '/add-child-physical.jpg');
      }
      &.addchild_diet {
        // background-position: center;
        background-image: url($image-path + '/add-child-diet.jpg');
      }
      &.addchild_allergy {
        // background-position: center;
        background-image: url($image-path + '/add-child-allergy.jpg');
      }

      img {
        height: 100%;
        width: 100%;
      }
      &_imageshadow {
        background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0), #000000);
        height: 100%;
        position: absolute;
        bottom: 0;
        width: 100%;
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .signup_container {
    .signup {
    }
  }
}
@media only screen and (max-width: 768px) {
  .signup_container {
    // display: block;
    overflow-y: auto;
    .signup {
      // width: 90%;
      // height: auto;
      // margin: auto auto 1rem auto;
      width: 100%;
      padding: 4rem 2rem;
      margin: auto;
      margin: 0;
      &_title {
        width: 90%;
        margin: auto auto 0 auto;
      }
      &_carousel {
        width: 100%;
        height: 100%;
        padding: 1rem;
        margin: 4rem 0 3rem;
        border: 2px solid $color-lightgrey-ternary;
        background-image: none;
        border-radius: 6px;
        position: relative;
        top: 0;
        overflow: visible;
        &_icon {
          position: absolute;
          top: -22px;
          left: 42%;
          height: 50px;
          background: $color-white-primary;
          padding: 0 12px;
          img {
            background: $color-white-primary;
          }
        }
      }
      &_input {
        width: 100%;
      }
    }
  }
}
