@import '../../scss';

.transactionHistory {
  &_container {
    @include containerHeight;
    @include flexCenter;
    align-items: flex-start;
  }
  padding: 5rem 0;
  width: 100%;
  @include flexCenter;
  flex-direction: column;
  &__filter {
    margin: 2rem 0 3rem;
  }
  &__table {
    width: 60%;
    margin: 0 0 3rem;
    &_total {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem 2rem 0 2rem;
      @include text('Roboto', 1.5rem, 500, normal, normal, 1, normal);
      color: $color-grey-primary;
    }
    .amount-in {
      color: $color-snot-green-primary;
    }
    .amount-out {
      color: $color-red-primary;
    }
    &_pagination {
      margin: 2rem 0 0;
      @include flexCenter;
    }
  }
  &__btn {
    @include flexCenter;
    width: 160px;
  }
  &__loader {
    position: absolute;
    top: 60%;
    transform: translate(0, -60%);
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .transactionHistory {
    &__table {
      width: 75%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .transactionHistory {
    padding: 3rem 1rem;
    width: 100%;
    &__table {
      width: 100%;
      &_list {
        &_wrapper {
          box-shadow: 0 4px 10px 0 $color-black-rgba-primary;
          border: solid 1px $color-black-secondary;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include text('Roboto', 1.5rem, normal, normal, normal, 1.5, normal);
        padding: 1.5rem 1rem;
        border-bottom: 1px solid $color-black-secondary;
        color: $color-grey-primary;
        word-break: break-word;
        &_right {
          text-align: right;
        }
        &_bold {
          font-family: 'Roboto';
          font-weight: 500;
        }
      }
    }
  }
}
