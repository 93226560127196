@import '../../scss';

.contactdetails {
  &_container {
    @include containerHeight;
    @include flexCenter;
  }

  @include flexCenter;
  flex-direction: column;
  flex: 1;
  margin: auto;
  &__input {
    width: 340px;
  }
}

@media only screen and (max-width: 768px) {
  .contactdetails {
    padding: 2rem;
    &__input {
      width: 100%;
    }
  }
}
