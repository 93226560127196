@import '../../scss';

.toggleSeverity {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 1.5rem;
  border-bottom: 1px solid $color-black-secondary;
  &__title {
    @include text('Roboto', 1.5rem, normal, normal, normal, normal, normal);
    color: $color-grey-secondary;
  }
  &__list {
    display: flex;
    &_item {
      cursor: pointer;
      width: 80px;
      height: 28px;
      border-radius: 16.5px;
      background-color: $color-lightgrey-secondary;
      @include text('Roboto', 1.2rem, normal, normal, normal, normal, normal);
      line-height: 28px;
      text-align: center;
      color: $color-grey-secondary;
      &:not(:last-child) {
        margin: 0 0.5rem 0 0;
      }
      &.blue.active {
        @include bgGradientBlue(305deg);
        color: $color-white-primary;
      }
      &.active {
        @include bgGradientYellow(305deg);
        color: $color-white-primary;
      }
    }
  }
}

@media only screen and (min-width: 320px) and (max-width: 350px) {
  .toggleSeverity {
    &__list {
      &_item {
        width: 70px;
      }
    }
  }
}
