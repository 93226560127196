@import '../../scss';

.credithistory {
  &__table {
    &_total {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      padding: 2rem 2rem 0 2rem;
      @include text('Roboto', 1.5rem, 500, normal, normal, 1, normal);
      color: $color-grey-primary;
    }
    &_AED {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      color: $color-green-primary;
    }
    &_pagination {
      display: flex;
      justify-content: center;
      &_btn {
        width: 140px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .credithistory {
    &__table {
      &_total {
        padding: 2rem;
      }
      &_list {
        &_wrapper {
          box-shadow: 0 4px 10px 0 $color-black-rgba-primary;
          border: solid 1px $color-black-secondary;
        }
        &__noData {
          text-align: center;
          padding: 2rem 0;
          @include text('Roboto', 1.5rem, normal, normal, normal, 1.5, normal);
          color: $color-grey-primary;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include text('Roboto', 1.5rem, normal, normal, normal, 1.5, normal);
        padding: 1.5rem 1rem;
        border-bottom: 1px solid $color-black-secondary;
        color: $color-grey-primary;
        word-break: break-word;
      }
    }
  }
}
