@import '../../scss';

.schoolinfo {
  @include containerHeight;
  @include flexCenter;
  width: 100%;

  &__container {
    font-family: Roboto, sans-serif;
    font-size: 16px;
    text-align: center;

    &__button {
      text-align: center;
    }

    &__details {
      width: 100%;
      margin: 3rem 0;
      border-radius: 8px;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.03);
      border: solid 1px $color-black-secondary;
      background-color: $color-white-primary;
      padding: 2rem;
      text-align: center;
      &_signup {
        font-weight: bold;
        margin: 2rem 0 1rem;
        font-size: 22px;
      }
      &_para {
        margin: 0.5rem 0;
      }
      &_bold {
        font-weight: bold;
      }
    }
    &__image {
      position: relative;
      &_desktop {
        display: block;
        margin: auto;
      }
      &_mobile {
        display: none;
      }
      &_error {
        @include text('Roboto', 6.4rem, bold, normal, normal, normal, normal);
        @include absCenter;
        color: $color-red-ternary;
      }
    }
    &__text {
      padding: 2rem 0;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .schoolinfo {
    .schoolinfo__container {
      width: 50%;
    }
  }
}
@media only screen and (max-width: 768px) {
  .schoolinfo {
    .schoolinfo__container {
      width: 90%;
      padding: 3rem 0;
      &__image {
        &_desktop {
          display: none;
        }
        &_mobile {
          display: block;
          margin: auto;
        }
      }
      &__card {
        width: 100%;
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .schoolinfo {
    padding: 8rem 0;
    .schoolinfo___container {
      margin: 0 !important;
    }
  }
}
