@import '../../scss';

.nutrition-insights {
  width: 590px;
  &__heading {
    @include text('Roboto', 2.8rem, normal, normal, normal, normal, normal);
    color: $color-grey-primary;
    text-align: center;
  }
  &__list {
    border-radius: 8px;
    box-shadow: 0 4px 10px 0 $color-black-rgba-primary;
    border: solid 1px $color-black-secondary;
    background-color: $color-white-primary;
    margin: 2rem 0 4rem;
    &_item {
      padding: 2.5rem;
      color: $color-grey-primary;
      &__title {
        @include text('Roboto', 1.6rem, 500, normal, normal, 1.25, normal);
      }
      &__description {
        padding: 0.5rem 0 1rem;
        @include text('Roboto', 1.4rem, normal, normal, normal, 1.36, normal);
      }
      &__btn {
        width: 200px;
      }
      &:not(:last-child) {
        border-bottom: 1px solid $color-black-secondary;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .nutrition-insights {
    width: 100%;
  }
}
