@import '.';

.u_position_fixed {
  position: fixed;
}
.u_position_absolute {
  position: absolute;
}
.u_position_relative {
  position: relative;
}

.u_clear_both {
  clear: both;
}
.u_float_left {
  float: left;
}
.u_float_right {
  float: right;
}

.u_text_center {
  text-align: center;
}
.u_text_right {
  text-align: right;
}
.u_text_left {
  text-align: left;
}

.u_text_transform_uppercase {
  text-transform: uppercase;
}
.u_text_transform_capitalize {
  text-transform: capitalize;
}
.u_text_decoration {
  text-decoration: none;
}
.u_text_decoration_underline {
  text-decoration: underline;
}

.u_font_weight_bold {
  font-weight: bold;
}

.u_margin_auto {
  margin: 0 auto;
}
.u_margin_bottom_0 {
  margin-bottom: 0;
}
.u_opacity_0 {
  opacity: 0;
}

.u_display_block {
  display: block;
}
.u_display_inline_block {
  display: inline-block;
}

.u_align_vertical_top {
  vertical-align: top;
}
.u_align_vertical_middle {
  vertical-align: middle;
}

.u_background_none {
  background: none;
}
.u_border_none {
  border: none;
}
.u_display_none {
  display: none;
}
.u_list_style_none {
  list-style-type: none;
}
.u_mobile_display_block {
  display: none;
}

.u_margin_1rem_top_bot {
  margin: 1rem 0;
}
.u_margin_2rem_top_bot {
  margin: 2rem 0;
}
.u_margin_3rem_top_bot {
  margin: 3rem 0;
}
.u_margin_1rem_rgt {
  margin-right: 1rem;
}

.u_cursor_pointer {
  cursor: pointer;
}

.u_overlay {
  position: absolute;
  width: 100%;
  text-align: center;
  top: 0;
  bottom: 0;
  background: $color-dark-black-primary;
  opacity: 0.65;
  left: 0;
  right: 0;
  overflow: hidden;
  @include text('Roboto', 2rem, 300, normal, normal, normal, normal);
  padding: 10rem;
  color: $color-white-primary;
}

.u_overlay_text {
  @include text('Roboto', 2rem, 300, normal, normal, normal, normal);
  @include absCenter;
  color: $color-grey-primary;
}

@media only screen and (max-width: 768px) {
  .u_mobile_display_none {
    display: none;
  }
  .u_mobile_display_block {
    display: block;
  }
}
