@mixin absCenter {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

@mixin verticalCenter {
  position: absolute;
  top: 50%;
  transform: translate(0%, -50%);
}

@mixin horizontalScollPane {
  white-space: nowrap;
  overflow: hidden;
  overflow-x: auto;
}

@mixin flexCenter {
  display: flex;
  align-items: center;
  justify-content: center;
}

@mixin bgGradientYellow($deg) {
  background-image: linear-gradient(
    $deg,
    $color-yellow-secondary,
    $color-yellow-ternary
  );
}

@mixin bgGradientBlack($deg) {
  background-image: linear-gradient(
    $deg,
    $color-grey-ternary,
    $color-grey-secondary
  );
}

@mixin bgGradientBlue($deg) {
  background-image: linear-gradient(
    $deg,
    $color-darkskyblue-secondary,
    $color-darkskyblue-ternary
  );
}

@mixin containerHeight {
  height: calc(100vh);
  padding-top: 76px;
  overflow-y: auto;
}

@mixin text($family, $size, $weight, $style, $stretch, $height, $spacing) {
  font-family: $family;
  font-size: $size;
  font-weight: $weight;
  font-style: $style;
  font-stretch: $stretch;
  line-height: $height;
  letter-spacing: $spacing;
}

@mixin loginText {
  font-family: 'Roboto';
  font-size: 1.2rem;
  font-weight: normal;
  font-style: normal;
  font-stretch: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $color-grey-secondary;
  margin: 1rem 0 0 0;
  cursor: pointer;
}
