@import '../../scss';

.tooltip {
  position: relative;
  display: inline-block;
  &_list {
    list-style-type: none;
    margin: 0;
    padding: 3rem;
    right: 0px;
    // left:0px;
    width: max-content;
    min-width: 160px;
    // max-width: max-content;
    background-color: white;
    font-weight: bold;
    position: absolute;
    border-radius: 6px;
    box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.07);
    z-index: 1;
    &:after {
      content: '';
      height: 0;
      width: 0;
      border-left: 6px solid transparent;
      border-right: 6px solid transparent;
      border-bottom: 6px solid #fff;
      position: absolute;
      top: -6px;
      right: 8%;
      margin: 0 0 0 -3px;
      z-index: 100;
    }
    &.center {
      left: 60%;
      transform: translate(-60%, 0);
      &:after {
        content: '';
        right: 42%;
      }
    }

    &_item {
      text-decoration: none;
      cursor: pointer;
      @include text('Roboto', 1.3rem, normal, normal, normal, normal, normal);
      color: $color-grey-primary;
      text-transform: uppercase;
      &--disabled {
        cursor: not-allowed;
        color: $color-grey-ternary;
      }
    }
    & li {
      &:not(:last-child) {
        padding: 0 0 1.5rem 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .tooltip {
    &_list {
      margin: 6px 0 0 0;
      right: -12px;
    }
  }
}
