@import '../../scss';

.mealListing-card {
  width: 100%;
  margin: 1rem 0;
  // &:not(:nth-child(3n)) {
  //   margin-right: 2%;
  // }
  background: #fff;
  cursor: pointer;
  position: relative;
  @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);
  &--inactive {
    opacity: 0.5;
  }
  &__added {
    width: 82px;
    border-radius: 11px;
    @include bgGradientYellow(295deg);
    text-align: center;
    color: $color-white-primary;
    padding: 2.5px 0;
    position: absolute;
    top: 10px;
    left: 10px;
  }
  &__img {
    flex: 0 0 auto;
    width: 30%;
    height: 200px;
    border-radius: 4px;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: 80% 100%;
    position: relative;
  }
  &__content {
    color: $color-grey-secondary;
    display: flex;
    flex-direction: row;
    // justify-content: space-between;
    // flex: 1;
    // align-items: center;
    @include text('Roboto', 1.6rem, 400, normal, normal, normal, normal);
    margin: 1rem 0;
    &_details {
      max-width: 25%;
      margin: 0 2rem;
      h3 {
        font-size: 2rem;
      }
      h4 {
        font-size: 1.75rem;
      }
      ul {
        padding: 0 2rem;
        margin: 10px 0;
        li {
          margin: 0.25rem 0;
        }
      }
      p {
        margin-top: 10px;
      }
    }
    &_price {
      // flex: 0.3;
      word-break: keep-all;
      text-align: right;
      @include text('Roboto', 3.6rem, 400, normal, normal, normal, normal);
    }
  }
}

@media only screen and (max-width: 768px) {
  .mealListing-card {
    padding: 0 1em;
    font-size: 1.3em;
    min-height: 100px;
    &__img {
      z-index: 0;
      left: 0;
      position: absolute;
      width: 100%;
      height: 100%;
      border-radius: 4px;
      background-repeat: no-repeat;
      background-size: cover;
      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: rgba(0, 0, 0, 0.5);
        background: linear-gradient(
          90deg,
          rgba(0, 0, 0, 0.35) 35%,
          rgba(0, 0, 0, 0) 80%,
          rgba(0, 0, 0, 0.35) 100%
        );
      }
    }
    &__added {
      top: auto;
      left: auto;
      bottom: 10px;
      right: 10px;
    }
    &__content {
      div {
        z-index: 1;
      }
      &_details {
        color: #fff;
        z-index: 1;
        max-width: 50%;
        margin: 0.5em;
        h3 {
          font-size: 1.4em;
        }
        p {
          margin: 10px 0;
        }
      }
    }
  }
}
