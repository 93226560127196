*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  outline: none;
  box-sizing: inherit;
  -webkit-tap-highlight-color: transparent;
}

html {
  font-size: 62.5%; // 1 rem = 10px; 10px/16px = 62.5%
  // background-color: #f5f5f5;
}

body {
  // background-color: $color-black-primary;
  max-width: 1920px;
  margin: 0 auto;
  box-sizing: border-box;
}
html,
body {
  height: 100%;
  width: 100%;
}
/* breakpoints 
  320px - 720px - For mobile.
  721px - 1023px - For tab.
  1024px - 1440px - For Desktop.
*/
@media all and (min-width: 1600px) {
  // define your rem value
}

@media all and (min-width: 1200px) {
  // define your rem value
}

@media all and (min-width: 992px) {
  // define your rem value
}

@media all and (min-width: 768px) {
  // define your rem value
}

@media all and (min-width: 576px) {
  // html {
  //     font-size: 93.75%; // 1 rem = 15px; 15px/16px = 93.75%
  // }
}

@media all and (max-width: 575px) {
  // define your rem value
}

/* breakpoints - retina */
@media (-webkit-min-device-pixel-ratio: 1.5),
  (min--moz-device-pixel-ratio: 1.5),
  (-o-min-device-pixel-ratio: 3/2),
  (min-resolution: 1.5dppx) {
  // define your rem value
}
