@import '../../scss';

.changePassword_container {
  @include containerHeight;
  @include flexCenter;
  .changePassword {
    padding: 2rem;
    width: 60%;
    &_input {
      width: 340px;
      margin: auto;
    }
  }
}

@media only screen and (max-width: 768px) {
  .changePassword_container {
    .changePassword {
      padding: 2rem;
      width: 90%;
      &_input {
        width: 100%;
      }
    }
  }
}
