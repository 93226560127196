@import '../../scss';

.notification_container {
  @include containerHeight;
  @include flexCenter;
  align-items: flex-start;
  .notification {
    padding: 3rem 0 6rem 0;
    &__list {
      width: 400px;
      &_heading {
        margin: 3rem 0 1.5rem 0;
        text-align: center;
        @include text('Roboto', 2.4rem, normal, normal, normal, normal, -0.7px);
        color: $color-grey-primary;
      }
      &_button {
        width: 100%;
        margin: 4rem 0 0;
      }
      &_loader {
        position: absolute;
        top: 60%;
        left: 50%;
        transform: translate(-50%, -60%);
      }
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
}
@media only screen and (max-width: 768px) {
  .notification_container {
    .notification {
      width: 100%;
      padding: 3rem 2rem 6rem 2rem;
      &__list {
        width: 100%;
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
}
