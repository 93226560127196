@import '../../scss';

.topup {
  @include flexCenter;
  flex-direction: column;
  padding: 5rem 0;
  margin: auto;
  flex: 1;
  width: 100%;
  &_loader {
    height: 200px;
    padding: 1.5rem 0;
  }
  &__card {
    width: 420px;
    &_balance {
      margin: 0 0 2rem 0;
    }
    &_calc {
      @include text('Roboto', 1.2rem, normal, normal, normal, normal, normal);
      text-align: center;
      color: $color-grey-secondary;
      width: 100%;
      padding: 1rem 6rem 0;
    }
  }
  &__btn {
    width: 360px;
    padding: 3rem 2rem 5rem;
    text-align: center;
  }
}

@media only screen and (max-width: 768px) {
  .topup {
    width: 100%;
    padding: 3rem 2rem;
    &__card {
      width: 100%;
      &_calc {
        padding: 1rem 3rem 2rem;
      }
    }
    &__btn {
      width: 100%;
      padding: 3rem 0;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .topup {
    height: 100%;
    &__card {
      &_calc {
        margin: 5px 0 60px;
      }
    }
  }
}
