@import '../../scss';

.deliveryplan {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: auto;
  padding: 4rem 0;
  width: 100%;
  &__term {
    width: 100%;
    @include flexCenter;
  }
  &__total {
    @include text('Roboto', 2.4rem, normal, normal, normal, normal, normal);
    color: $color-grey-primary;
    padding: 4rem 0 2rem;
  }
  &__button {
    width: 280px;
  }
}

@media only screen and (max-width: 768px) {
  .deliveryplan {
    padding: 3rem 2rem;
    &__total {
      @include text('Roboto', 2.4rem, normal, normal, normal, normal, normal);
      color: $color-grey-primary;
      padding: 2rem 0 3rem;
    }
    &__button {
      width: 100%;
    }
  }
}
