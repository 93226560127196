@import '../../scss';

.filterDropdown {
  width: 220px;
  height: 32px;
  position: relative;
  &__value {
    width: 100%;
    height: 100%;
    border-radius: 16.5px;
    background-color: $color-lightgrey-primary;
    display: flex;
    align-items: center;
    cursor: pointer;
    flex: 1;
    &_text {
      @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);
      text-transform: uppercase;
      text-align: center;
      color: $color-grey-secondary;
      flex: 0.9;
    }
    &_img {
      flex: 0.1;
    }
    &.blue {
      @include bgGradientBlue(168deg);
      & .filterDropdown__value_text {
        color: $color-white-primary;
      }
      & .filterDropdown__value_img {
        transform: scale(1.8) scaleX(1.8) rotate(-90deg);
        text-transform: lowercase;
        position: absolute;
        right: 20px;
        font-weight: bold;
        color: $color-white-primary;
      }
    }
  }
  &__list {
    max-height: 200px;
    overflow: auto;
    &_wrapper {
      box-shadow: 0 0 10px 3px $color-lightgrey-primary;
      background: $color-white-primary;
      position: absolute;
      width: 100%;
      z-index: 10;
    }
    &_item {
      padding: 1rem;
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      &_text {
        @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);
        width: 94%;
        text-transform: uppercase;
        color: $color-grey-secondary;
      }
      &:hover {
        background: $color-lightgrey-primary;
      }

      & .checkmark {
        width: 16px;
        height: 16px;
        background-color: $color-white-primary;
        position: relative;
        border-radius: 12.5px;
        margin-right: 0.4rem;
        &::after {
          display: block;
          content: '';
          left: 6px;
          top: 3px;
          width: 5px;
          height: 10px;
          border: solid $color-grey-primary;
          border-width: 0 1.5px 1.5px 0;
          transform: rotate(45deg);
          position: absolute;
        }
      }
    }
  }
}
