@import '../../scss';

.earncredit_container {
  @include containerHeight;
  @include flexCenter;
  align-items: flex-start;
  .earncredit {
    width: 60%;
    @include flexCenter;
    flex-direction: column;
    padding: 5rem 0;
    &__description {
      @include text('Roboto', 1.6rem, normal, normal, normal, 1.38, normal);
      color: $color-grey-primary;
      width: 75%;
      text-align: center;
      &_bold {
        font-weight: bold;
      }
    }
    &__invites {
      &_title {
        @include text('Roboto', 2.4rem, normal, normal, normal, normal, -0.7px);
        color: $color-grey-primary;
        text-align: center;
        padding: 4rem 0 2rem 0;
      }
      &_input {
        width: 360px;
      }
    }
    &__share {
      width: 360px;
    }
    &__history {
      width: 600px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .earncredit_container {
    align-items: flex-start;
    .earncredit {
      width: 100%;
      padding: 2rem;
      &__description {
        width: 100%;
      }
      &__invites {
        width: 100%;
        &_input {
          width: 100%;
        }
      }
      &__share {
        width: 100%;
      }
      &__history {
        width: 100%;
      }
    }
  }
}
