@import 'scss/base';
@import 'scss/typography';
@import 'scss/utilities';

.app {
  margin: 0;
  padding: 0;
  outline: none;
  width: 100%;
  height: 100%;
}
