@import '../../scss';

.nutrition-items {
  @include flexCenter;
  flex-direction: column;
  width: 100%;
  &__table {
    width: 60%;
    margin: 2rem 0 3rem;
    .good {
      color: $color-snot-green-primary;
    }
    .bad {
      color: $color-red-primary;
    }
    .average {
      color: $color-grey-primary;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .nutrition-items {
    &__table {
      width: 75%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .nutrition-items {
    &__table {
      width: 100%;
      &_list {
        &_wrapper {
          box-shadow: 0 4px 10px 0 $color-black-rgba-primary;
          border: solid 1px $color-black-secondary;
        }
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        @include text('Roboto', 1.5rem, normal, normal, normal, 1.5, normal);
        padding: 1.5rem 1rem;
        border-bottom: 1px solid $color-black-secondary;
        color: $color-grey-primary;
        word-break: break-word;
        &_bold {
          font-family: 'Roboto', sans-serif;
          font-weight: 500;
        }
      }
    }
  }
}
