@import '../../scss';

.nutrition-cons {
  &__score {
    &_wrapper {
      width: 100%;
      @include flexCenter;
      margin: 0 0 3rem 0;
    }
    width: 290px;
  }
  &__chart {
    width: 300px;
    height: 300px;
    position: relative;
  }
  &_overlay {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .nutrition-cons {
    &__score {
      &_wrapper {
        display: block;
      }
      width: 100%;
    }
  }
}
