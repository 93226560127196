@import '../../scss';

.inputmultiplevalue {
  border-bottom: 2px solid $color-grey-secondary-rgba;
  padding: 0 8px;
  position: relative;
  margin: 30px 0px;
  @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);
  & input {
    outline: none;
    border: none;
    @include text('Roboto', 1.6rem, normal, normal, normal, normal, normal);
    color: $color-grey-secondary;
    margin: 4px 0;
    width: 100%;
    max-width: 100% !important;

    -ms-overflow-style: none;
    overflow: -moz-scrollbars-none;
    &::-webkit-scrollbar {
      width: 0 !important;
    }
  }

  .textarea_wrapper {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    width: 100%;
    margin-bottom: 5px;
  }
  .textarea {
    &_list {
      border-radius: 12px;
      background-color: $color-lightgrey-primary;
      padding: 1rem;
      @include text('Roboto', 1.2rem, normal, normal, normal, normal, normal);
      color: $color-grey-secondary;
      word-break: break-all;
      line-height: 28px;
      padding: 0 1.5rem;
      margin: 0 1rem 0.5rem 0;
      &_remove {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        padding-left: 1rem;
        cursor: pointer;
      }
    }
  }

  &.hasvalue label {
    transform: translate(0, -20px) scale(0.75);
    opacity: 1;
    color: $color-grey-secondary;
    min-height: auto;
  }

  &.active {
    border-bottom: 2px solid $color-yellow-primary;
    min-height: auto;
    & label {
      transform: translate(0, -20px) scale(0.8);
      opacity: 1;
      color: $color-yellow-one;
      font-size: 1.4rem;
    }
  }

  &.error {
    border-bottom: 2px solid $color-red-primary;
    & label {
      transform: translate(0, -20px) scale(0.75);
      opacity: 1;
      color: $color-red-primary;
      font-size: 1.4rem;
    }
  }

  label {
    font-size: 1.6rem;
    position: absolute;
    transform-origin: top left;
    transform: translate(0, 5px) scale(1);
    transition: all 0.1s ease-in-out;
    color: $color-grey-secondary;
  }
}
