@import '../../scss';

.reportcardlostSuccess {
  @include flexCenter;
  flex-direction: column;
  margin: auto;
  padding: 5rem 0;
  flex: 1;
  width: 100%;
  &__image {
    position: relative;
    &_desktop {
      display: block;
      margin: auto;
    }
    &_mobile {
      display: none;
    }
  }
  &__text {
    padding: 2rem 0 0;
  }
  &__btn {
    width: 360px;
  }
}

@media only screen and (max-width: 768px) {
  .reportcardlostSuccess {
    width: 100%;
    padding: 3rem 2rem;
    &__image {
      &_desktop {
        display: none;
      }
      &_mobile {
        display: block;
        margin: auto;
      }
    }
    &__btn {
      width: 100%;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .reportcardlostSuccess {
    height: 100%;
  }
}
