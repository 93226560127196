@import '../../scss';

.deletechildren_container {
  @include containerHeight;
  @include flexCenter;
  .deletechildren {
    &__button {
      &-des {
        width: 380px;
        margin: 1rem auto;
        &_button {
          min-width: 180px;
          border-radius: 50px;
          &:not(:last-child) {
            margin-right: 20px;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .deletechildren_container {
    .deletechildren {
      width: 90%;
      margin: auto;
      &__button {
        &-mob {
          margin: 1rem 0 0;
        }
      }
    }
  }
}
