@import '../../scss';

.limitCard {
  min-height: 106px;
  height: 106px;
  width: 100%;
  @include flexCenter;
  flex-direction: column;
  flex: 1;
  color: $color-grey-primary;
  background-color: $color-lightpink-primary;
  border-radius: 6px;
  margin: 3rem 0 0.5rem;
  &__mainText {
    @include text('Roboto', 1.8rem, normal, normal, normal, normal, normal);
  }
  &__subText {
    @include text('Roboto', 2.2rem, 500, normal, normal, normal, normal);
    &.red {
      color: $color-red-secondary;
    }
  }
}

@media only screen and (max-width: 768px) {
  .limitCard {
    width: 100%;
  }
}
