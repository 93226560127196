@import '../../scss';

.payment {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex: 1;
  margin: auto;
  padding: 5rem 0;
  width: 100%;

  &__card {
    width: 360px;
    &_btn {
      margin: 3rem 0 1rem;
    }
  }

  & .overlay {
    position: absolute;
    top: -20px;
    bottom: -15px;
    right: 0;
    left: 0;
    background: $color-dark-black-primary;
    opacity: 0.65;
    text-align: center;
    &_text {
      margin-top: 30px;
      @include text('Roboto', 2rem, normal, normal, normal, normal, normal);
      color: $color-white-primary;
    }
    &_wrapper {
      position: relative;
    }
  }
}

@media only screen and (max-width: 768px) {
  .payment {
    padding: 4rem 2rem;
    &__card {
      width: 100%;
    }
  }
}
