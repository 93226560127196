@import '../../scss';

.resetDone {
  &_image {
    &_desktop {
      display: block;
      margin: auto;
    }
    &_mobile {
      display: none;
    }
  }
  &_text {
    padding: 2rem 0;
  }
  &_button {
    width: 360px;
    margin: auto;
  }
}

@media only screen and (max-width: 768px) {
  .resetDone {
    &_image {
      &_desktop {
        display: none;
      }
      &_mobile {
        display: block;
        margin: auto;
      }
    }
    &_button {
      width: 100%;
    }
  }
}
