@import '../../scss';

.toggleTabs {
  &_heading {
    @include text('Roboto', 2.4rem, normal, normal, normal, normal, normal);
    color: $color-grey-primary;
    text-align: center;
    padding: 3rem 0 1rem;
  }
  &_list {
    width: 620px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    &_item {
      height: 74px;
      width: 200px;
      cursor: pointer;
      position: relative;
      @include text('Roboto', 2rem, 500, normal, normal, normal, normal);
      text-align: center;
      color: $color-grey-primary;
      background-color: $color-lightgrey-primary;
      border-radius: 6px;
      &_text {
        width: 100%;
        @include absCenter;
      }
      &.active {
        @include bgGradientYellow(307deg);
        color: $color-white-primary;
      }
      .selected {
        font-size: 1.2rem;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .toggleTabs {
    width: 100%;
    &_heading {
      @include text('Roboto', 2rem, normal, normal, normal, normal, normal);
    }
    &_list {
      width: 100%;
      &_item {
        height: 60px;
        width: 31.5%;
        &_text {
          width: 100%;
          font-size: 1.6rem;
        }
        .selected {
          font-size: 1rem;
        }
      }
    }
  }
}
