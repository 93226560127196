// colors

$color-black-primary: #222222;
$color-dark-black-primary: #000000;
$color-black-secondary: #eeeeee;

$color-black-rgba: rgba(255, 255, 255, 0.5);
$color-black-rgba-primary: rgba(0, 0, 0, 0.03);
$color-black-rgba-secondary: rgba(0, 0, 0, 0.04);
$color-black-rgba-ternary: rgba(255, 255, 255, 0.7);
$color-black-rgba-tertiary: rgba(255, 255, 255, 0.92);

$color-white-primary: #fff;

$color-grey-primary: #3f3f49;
$color-grey-secondary: #494b46;
$color-grey-ternary: #80827c;
$color-grey-tertiary: #3d3f3b;

$color-greyish-primary: #b5b5b5;

$color-greyish-brown-two: #444444;
$color-greyish-brown-one: rgba(76, 76, 76, 0.64);

$color-grey-secondary-rgba: rgba(73, 75, 70, 0.5);

$color-lightgrey-primary: #f5f5f5;
$color-lightgrey-secondary: #ededec;
$color-lightgrey-ternary: #e7e7e7;
$color-lightgrey-one: #e0e0df;

$color-red-primary: #f40100;
$color-red-secondary: #f40c0c;
$color-red-ternary: #ff0000;

$color-light-red-primary: #ef3332;

$color-light-red-rgba-primary: #ffcdcf;

$color-yellow-primary: #f2ae39;
$color-yellow-secondary: #ffd244;
$color-yellow-ternary: #ffaa19;
$color-yellow-one: #da9827;
$color-yellow-two: #df9f31;

$color-lightpink-primary: #fff4e0;

$color-darkblue-primary: #425f9e;
$color-darkblue-secondary: #0177b5;
$color-darkblue-ternary: #2e97e7;

$color-darkskyblue-primary: #2d98e7;
$color-darkskyblue-secondary: #2879bf;
$color-darkskyblue-ternary: #2d97e7;
$color-darkskyblue-tertiary: #2d97e6;

$color-skyblue-primary: #50abf1;

$color-cerulean-primary: #0079c5;

$color-green-primary: #65b600;

$color-snot-green-primary: #93c000;

$color-very-light-pink-primary: #fef2f2;

$color-duck-egg-blue-primary: #eaf4fc;

$color-mango-90: rgba(255, 190, 49, 0.9);

$alert-error-background: #f8d7da;
$alert-error-color: #721c24;
$alert-error-border: #f5c6cb;

$alert-success-background: #d4edda;
$alert-success-color: #155724;
$alert-success-border: #c3e6cb;

// images

$image-path: '../../assets';
