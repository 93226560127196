@import '../../scss';

.paymentCard {
  width: 100%;
  background-color: $color-lightgrey-primary;
  border-radius: 6px;
  padding: 2rem;
  margin: 1rem 0 0;
  display: flex;
  align-items: center;
  justify-content: space-between;
  cursor: pointer;

  &__mainText {
    color: $color-grey-tertiary;
    @include text('Roboto', 1.6rem, 500, normal, normal, normal, normal);
  }
  &__subText {
    color: $color-grey-secondary;
    @include text('Roboto', 1.4rem, normal, normal, normal, 1.29, normal);
  }
}
