@import '../../scss';

.nutrition_container {
  @include containerHeight;
  @include flexCenter;
  align-items: flex-start;
  .nutrition {
    width: 100%;
    @include flexCenter;
    flex-direction: column;
    align-items: center;
    padding: 0 0 5rem 0;
    &__name {
      padding: 3rem 0 2rem 0;
    }
    &__loader {
      height: 300px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .nutrition_container {
    .nutrition {
      width: 100%;
      padding: 2rem;
    }
  }
}
