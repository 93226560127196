@import '../../scss';

.react-autosuggest__container {
  height: 30px;
  position: absolute;
  bottom: 0;
  width: 100%;
  @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);

  input[type='text' i]:disabled {
    background: transparent;
    cursor: not-allowed;
    @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);
  }
}

.autosuggest {
  position: relative;
  height: 48px;
  margin: 20px 0px;
  border-bottom: 2px solid $color-grey-secondary-rgba;
  @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);
  padding: 0 8px;

  &.active {
    border-bottom: 2px solid $color-yellow-primary;
    .autosuggest-label {
      transform: translate(0, 1px) scale(0.75);
      opacity: 1;
      color: $color-yellow-one;
      font-size: 1.6rem;
    }
  }

  &.error {
    border-bottom: 2px solid $color-red-primary;
    .autosuggest-label {
      transform: translate(0, 1px) scale(0.75);
      opacity: 1;
      color: $color-red-primary;
      font-size: 1.6rem;
    }
  }

  &.hasvalue .autosuggest-label {
    transform: translate(0, 1px) scale(0.75);
    opacity: 1;
    color: $color-grey-secondary;
  }

  &_icon {
    position: absolute;
    right: 20px;
    top: 41%;
  }
}

.autosuggest-label {
  font-size: 1.6rem;
  position: absolute;
  transform-origin: top left;
  transform: translate(0, 16px) scale(1);
  transition: all 0.1s ease-in-out;
  color: $color-grey-secondary;
  // z-index: 1;
}

.react-autosuggest__input {
  width: 92%;
  border: none;
  border-radius: 4px;
  z-index: 1;
  @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);
  color: $color-grey-secondary;
  margin-top: 4px;
  background-color: transparent;
}

.react-autosuggest__input--focused {
  outline: none;
}

.react-autosuggest__input--open {
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
}

.react-autosuggest__suggestions-container {
  display: none;
}

.react-autosuggest__suggestions-container--open {
  display: block;
  position: absolute;
  top: 40px;
  width: 100%;
  margin-left: -8px;
  box-shadow: 0 0 10px 3px $color-lightgrey-primary;
  background-color: $color-white-primary;
  font-weight: 300;
  font-size: 15px;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  z-index: 4;
  max-height: 203px;
  overflow-x: auto;
}

.react-autosuggest__suggestions-list {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

.react-autosuggest__suggestion {
  cursor: pointer;
  padding: 10px 20px;
}

.react-autosuggest__suggestion--highlighted {
  background-color: $color-lightgrey-primary;
}

.react-autosuggest__section-container {
  border-top: 1px dashed #ccc;
  color: $color-lightgrey-primary;
}

.react-autosuggest__section-container--first {
  border-top: 0;
}

.react-autosuggest__section-title {
  padding: 10px 0 0 10px;
  font-size: 12px;
  color: #777;
}
