@import '../../scss';

.slider {
  width: 100%;
  height: 100%;
  z-index: 1;
  &_item {
    display: none;
    &-bg1 {
      background-image: url($image-path + '/signup-slider-1.png');
    }
    &-bg2 {
      background-image: url($image-path + '/signup-slider-2.png');
    }
    &-bg3 {
      background-image: url($image-path + '/signup-slider-3.png');
    }
    &.active {
      -webkit-animation-name: fade-in;
      -webkit-animation-duration: 1.5s;
      animation-name: fade-in;
      animation-duration: 1.5s;
      display: block;
      position: absolute;
      top: 0;
      bottom: 0;
      right: 0;
      left: 0;
      background-size: 100%;
    }
    &_content {
      width: 100%;
      position: absolute;
      bottom: 23px;
      background: $color-mango-90;
      padding: 1.5rem 12rem 2rem;
      &_title {
        @include text(
          'Open Sans',
          1.4rem,
          bold,
          normal,
          normal,
          normal,
          normal
        );
        text-align: center;
        padding: 0.5rem 0;
        color: $color-white-primary;
      }
      &_description {
        @include text(
          'Open Sans',
          1.4rem,
          normal,
          normal,
          normal,
          normal,
          normal
        );
        text-align: center;
        color: $color-white-primary;
      }
    }
  }
  &_indicator {
    text-align: center;
    padding: 0 0 1rem;
    position: absolute;
    width: 100%;
    bottom: 0;
    background: $color-mango-90;
    &_item {
      height: 11px;
      width: 11px;
      margin: 0 5px;
      border-radius: 50%;
      display: inline-block;
      border: 1px solid $color-white-primary;
      // transition: background-color 3s ease;
      &.active {
        background-color: $color-white-primary;
      }
    }
  }

  @-webkit-keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }

  @keyframes fade {
    from {
      opacity: 0.4;
    }
    to {
      opacity: 1;
    }
  }
}

@media only screen and (max-width: 768px) {
  .slider {
    position: relative;
    margin: auto;
    bottom: auto;
    left: auto;
    right: auto;
    height: auto;
    padding: 2.5rem 1rem 0rem;

    &_item {
      &.active {
        position: static;
      }
      &-bg1 {
        background-image: none;
      }
      &-bg2 {
        background-image: none;
      }
      &-bg3 {
        background-image: none;
      }
      &_content {
        position: static;
        background: $color-white-primary;
        padding: 0px;
        &_title {
          @include text(
            'Roboto',
            1.8rem,
            normal,
            normal,
            normal,
            normal,
            -0.5px
          );
          text-align: center;
          padding: 1rem 0;
          color: $color-grey-primary;
        }
        &_description {
          @include text('Roboto', 1.4rem, normal, normal, normal, 1.29, normal);
          text-align: center;
          color: $color-grey-primary;
        }
      }
    }
    &_indicator {
      text-align: center;
      padding: 1rem 0;
      position: static;
      background: $color-white-primary;
      &_item {
        height: 6px;
        width: 6px;
        margin: 0 2px;
        background-color: $color-lightgrey-one;
        border-radius: 50%;
        display: inline-block;
        // transition: background-color 3s ease;
        &.active {
          background-color: $color-grey-secondary;
        }
      }
    }
  }
}
