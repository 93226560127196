@import '../../scss';

.container {
  @include containerHeight;
  display: flex;
  justify-content: center;

  &__loader {
    @include flexCenter;
    height: 80vh;
  }

  .lunchOrder {
    width: 70%;
    padding: 2rem 0 7rem;
    margin: auto;
    &_category {
      width: 100%;
      &-wrapper {
        list-style: none;
        .no-content {
          height: 300px;
          text-align: center;
          position: relative;
          display: flex;
          @include text(
            'Roboto',
            2.5rem,
            normal,
            normal,
            normal,
            normal,
            normal
          );
          color: $color-grey-secondary;
          align-items: center;
          justify-content: center;
          flex-direction: column;
        }
      }
      &:first-child {
        margin: 4rem 0 0;
      }
      &__menuTitle {
        margin: 4rem 0 1rem;
        @include text('Roboto', 4rem, normal, normal, normal, normal, normal);
        color: $color-grey-primary;
      }
      &__title {
        margin: 1.5rem 0;
        @include text('Roboto', 2.4rem, normal, normal, normal, normal, normal);
        color: $color-grey-primary;
      }
      &__mealListing {
        @include flexCenter;
        flex-direction: column;
        flex-wrap: wrap;
      }
      &__itemError {
        align-self: start;
        color: #999;
        margin: 1.5rem 0;
        max-width: 100%;
        @include text('Roboto', 2.4rem, normal, normal, normal, normal, normal);
      }
    }
    &_button {
      margin: 2rem auto;
      text-align: center;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .container {
    .lunchOrder {
      width: 80%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    padding-top: 56px;

    .lunchOrder {
      width: 100%;
      padding: 7rem 0 4rem 0;
      &_category {
        border-bottom: 1px solid $color-lightgrey-secondary;
        margin: 1rem 0;
        padding: 0 1rem;
        &-wrapper {
          .no-content {
            height: 200px;
            font-size: 2rem;
          }
        }
        &:first-child {
          margin: 2rem 0 0;
          border-top: 1px solid $color-lightgrey-secondary;
        }
        &__menuTitle {
          margin: 0;
          text-align: left;
          padding: 1rem 1rem 0;
          font-size: 3rem;
        }
        &__title {
          font-size: 2rem;
          text-align: left;
          margin: 0;
        }
        &__itemError {
          margin: 1.2rem 0;
          @include text(
            'Roboto',
            1.6rem,
            normal,
            normal,
            normal,
            normal,
            normal
          );
        }
      }
      &_button {
        width: 100%;
        margin: auto;
        button {
          width: 100%;
          left: 0;
          height: 50px;
          z-index: 2;
          border-radius: 0;
          position: fixed;
          bottom: 0;
        }
      }
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .container {
    height: auto;
    position: absolute;
    top: 100px;
    padding-top: 0px;
    width: 100%;
    .lunchOrder {
      margin: 0 !important;
    }
  }
}
