@import '../../scss';

.headeruser {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
  &_wrapper {
    @include flexCenter;
    background: $color-lightgrey-primary;
    padding: 0.5rem 1.5rem 0.5rem 4rem;
    border-radius: 25px;
  }
  &__detail {
    @include text('Roboto', 1.4rem, 500, normal, normal, normal, normal);
    text-align: right;
    color: $color-grey-primary;
    padding: 0 1rem 0 0;
    &_name {
      font-family: 'Roboto', sans-serif;
      font-weight: 500;
      font-size: 1.4rem;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    &_status {
      font-family: 'Roboto', sans-serif;
      font-size: 1.1rem;
      max-width: 300px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      &_text {
        padding-left: 0.3rem;
      }
    }
  }
}

@media only screen and (max-width: 400px) {
  .headeruser {
    &__detail {
      &_name {
        max-width: 130px;
      }
      &_status {
        max-width: 130px;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .headeruser {
    &_wrapper {
      background: transparent;
      padding: 0;
    }
    &_menu {
      &_icon {
        display: inline-block;
        .bar {
          width: 20px;
          height: 2px;
          background-color: $color-grey-secondary;
          margin: 4px 0;
        }
      }
    }
  }
}
