@import '../../scss';

.progressTrack {
  @include flexCenter;
  &.disableSplit {
    width: 100%;
    justify-content: space-evenly;
  }
  &_item {
    @include flexCenter;
    @include text('Roboto', 1.3rem, normal, normal, normal, normal, normal);
    opacity: 0.5;
    cursor: not-allowed;
    color: $color-grey-primary;
    &:not(:last-child):after {
      display: inline-block;
      content: '';
      width: 12px;
      height: 2px;
      background-color: $color-grey-secondary;
      vertical-align: middle;
      margin: 0 1rem;
    }
    &_name {
      text-align: center;
      text-transform: uppercase;
    }
    &.active {
      opacity: 1;
      color: $color-yellow-primary;
      cursor: pointer;
      &.vendor {
        color: $color-darkskyblue-primary;
      }
    }
    &.done {
      opacity: 1;
      color: $color-grey-secondary;
      cursor: pointer;
    }

    &.disableSplit {
      @include text('Roboto', 1.6rem, 500, normal, normal, normal, normal);
      padding-right: 2.5rem;
      &:after {
        display: none;
      }
      &.done {
        font-family: 'Roboto', sans-serif;
        font-weight: normal;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .progressTrack {
    &_item {
      font-size: 1rem;
      &:not(:last-child):after {
        margin: 0 0.4rem;
      }
      &.disableSplit {
        font-size: 1.3rem;
        padding-right: 1rem;
      }
    }
  }
}
