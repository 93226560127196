@import '../../scss';

.pagination {
  transition: all 0.1s;
  &_list {
    display: flex;
    list-style-type: none;
    &_item {
      width: 24px;
      height: 24px;
      background-color: $color-lightgrey-secondary;
      border-radius: 50px;
      text-align: center;
      margin: 0.5rem;
      color: $color-grey-primary;
      @include text('Roboto', 1.2rem, 500, normal, normal, 24px, normal);
      cursor: pointer;
      &.active {
        background-color: $color-grey-secondary;
        color: $color-white-primary;
      }
      &.prev {
        margin-right: 1rem;
        transform: rotate(90deg);
      }
      &.next {
        transform: rotate(-90deg);
        margin-right: 1rem;
      }
    }
  }
}
