@import '../../scss';

.signupallergy {
  &_description {
    color: $color-greyish-brown-two;
    font-family: 'Open Sans';
    text-align: left;
    font-weight: normal;
    line-height: normal;
  }
  &_info {
    margin: 3rem 5rem;
    border: solid 1px $color-black-secondary;
    background-color: $color-white-primary;
    padding: 1rem;
    &_icon {
      width: 20px;
    }
    &_content {
      padding: 0 0 0 20px;
      @include text(
        'Open Sans',
        1.6rem,
        normal,
        normal,
        normal,
        normal,
        normal
      );
      color: $color-grey-primary;
      margin-top: -6px;
    }
  }
  &_input {
    width: 65%;
    margin: 2.5rem auto;
    &_list {
      margin: 2rem 0 1rem 0;
      border-radius: 6px;
      border: solid 1px $color-black-secondary;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1150px) {
  .signupallergy {
    &_input {
      width: 75%;
    }
  }
}

@media only screen and (max-width: 768px) {
  .signupallergy {
    &_info {
      margin: 2rem 0;
    }
    &_input {
      width: 100%;
      &_list {
        width: 111%;
        margin-left: -5.5%;
        border-radius: 0;
        border: solid 1px $color-black-secondary;
        border-left: 0;
        border-right: 0;
      }
    }
  }
}
