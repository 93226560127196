@import '../../scss';

.spendinglimit_container {
  @include containerHeight;
  @include flexCenter;
  .spendinglimit {
    width: 60%;
    @include flexCenter;
    flex-direction: column;
    padding: 5rem 0;
    &__card {
      width: 360px;
    }
    &__input {
      width: 340px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .spendinglimit_container {
    align-items: flex-start;
    .spendinglimit {
      width: 100%;
      margin: 0 2rem;
      padding: 2rem 0;
      &__card {
        width: 100%;
      }
      &__input {
        width: 100%;
      }
    }
  }
}
