@import '../../scss';

.textarea {
  &__title {
    @include text('Roboto', 1.6rem, normal, normal, normal, normal, normal);
    color: $color-grey-secondary;
    padding: 1rem;
  }
  &__content {
    &_box {
      outline: none;
      width: 100%;
      border: none;
      resize: none;
      border-bottom: 2px solid $color-grey-secondary-rgba;
      @include text('Roboto', 1.5rem, normal, normal, normal, normal, normal);
    }
  }
}
