@import '../../scss';

.tables {
  border-radius: 8px;
  border: solid 1px $color-black-secondary;
  table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 10px 0 $color-black-rgba-primary;
    border-radius: 8px;
    border-style: hidden;
    background-color: $color-white-primary;
    tr {
      @include text('Roboto', 1.3rem, 500, normal, normal, normal, normal);
      height: 6.1rem;
      color: $color-black-primary;
      border-bottom: 1px solid $color-black-secondary;
    }
    th,
    td {
      padding-left: 2rem;
      padding-right: 2rem;
    }
    th {
      text-align: left;
      text-transform: uppercase;
      &.u_text_right {
        text-align: right;
      }
      img.sortIcon {
        padding: 0 0 1px 5px;
        cursor: pointer;
        &.rotate {
          transform: rotate(-180deg);
          padding: 0px 5px 0 0;
        }
      }
    }
    td {
      @include text('Roboto', 1.5rem, normal, normal, normal, normal, normal);
      color: $color-grey-primary;
      &.u_font_roboto_medium {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
      }
    }
  }
  &__row {
    border-radius: 4px;
    font-weight: 600;
    background-color: rgba(245, 245, 245, 0.2);
  }
  &__nodata {
    text-align: center;
    padding: 3.5rem 0;
    box-shadow: 0 4px 10px 0 $color-black-rgba-primary;
    @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);
    color: $color-grey-primary;
    border-top: solid 1px $color-black-secondary;
  }
}
