@import '../../scss';

.sharecredit {
  &__link {
    width: 100%;
    height: 60px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    border-radius: 53px;
    padding: 0 2rem;
    box-shadow: 0 4px 10px 0 $color-black-rgba-primary;
    border: solid 1px $color-black-secondary;
    background-color: $color-white-primary;
    &_text {
      width: 70%;
      input {
        @include text('Roboto', 1.8rem, normal, normal, normal, normal, normal);
        color: $color-grey-primary;
        border: none;
      }
    }
    &_btn {
      width: 90px;
      height: 28px;
      border-radius: 16.5px;
      background-color: $color-lightgrey-primary;
      @include text('Roboto', 1.2rem, normal, normal, normal, normal, normal);
      color: $color-grey-secondary;
      text-align: center;
      line-height: 28px;
      cursor: pointer;
    }
  }
  &__social {
    width: 340px;
    @include flexCenter;
    margin: 2rem 1rem;
    &_icon {
      width: 106px;
      height: 50px;
      border-radius: 6px;
      display: flex;
      justify-content: center;
      align-items: center;
      img {
        width: 22px;
        height: 16.8px;
      }
      &.facebook {
        background-color: $color-darkblue-primary;
        margin-right: 1rem;
      }
      &.twitter {
        background-color: $color-skyblue-primary;
        margin-right: 1rem;
      }
      &.linkedIn {
        background-color: $color-darkblue-secondary;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .sharecredit {
    &__social {
      width: 100%;
      margin: 2rem 0;
    }
  }
}
