@import '../../scss';

.calendar {
  @include flexCenter;
  flex-direction: row;
  padding: 3rem 0 0 0;
  user-select: none;
  &_left {
    font-size: 3rem;
    margin-right: 2rem;
    cursor: pointer;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
  &_right {
    font-size: 3rem;
    cursor: pointer;
    margin-left: 2rem;
    &.disabled {
      cursor: not-allowed;
      opacity: 0.4;
    }
  }
  &_item {
    @include flexCenter;
    flex-direction: column;
    width: 90px;
    height: 54px;
    line-height: 54px;
    border-radius: 6px;
    background-image: none;
    background: $color-lightgrey-primary;
    color: $color-grey-primary;
    cursor: pointer;
    position: relative;
    &:not(:last-child) {
      margin-right: 1rem;
    }
    &.disable {
      opacity: 0.4;
      cursor: not-allowed;
    }
    &.active {
      color: $color-white-primary;
      background-image: linear-gradient(315deg, #80827c, #494b46);
    }
    &__day {
      @include text('Roboto', 1rem, normal, normal, normal, normal, normal);
      text-transform: uppercase;
    }
    &__date {
      @include text('Roboto', 2rem, 500, normal, normal, normal, normal);
    }
    &__selected {
      height: 10px;
      width: 10px;
      background: $color-yellow-primary;
      border-radius: 50%;
      position: absolute;
      right: 5px;
      top: 10px;
    }
  }
}

@media only screen and (max-width: 768px) {
  .calendar {
    padding: 0;
    position: absolute;
    width: 100%;
    background-color: $color-white-primary;
    z-index: 2;
    top: 56px;
    &_left {
      display: none;
    }
    &_right {
      display: none;
    }
    &_item {
      border-radius: 0;
      width: 20%;
      &:not(:last-child) {
        margin-right: 0;
      }
    }
  }
}
