@import '../../scss';

.login_container {
  @include containerHeight;
  @include flexCenter;
  .login {
    &_input {
      width: 340px;
      margin: auto;
      &_forgot {
        @include text('Roboto', 1.2rem, normal, normal, normal, normal, normal);
        text-align: center;
        color: $color-grey-secondary;
        margin: 1rem 0 0 0;
        cursor: pointer;
        text-transform: uppercase;
      }
      &_radio {
        padding: 0 0 2rem 0;
        display: flex;
        align-items: center;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .login_container {
    .login {
      padding: 2rem;
      &_input {
        width: 100%;
      }
    }
  }
}
