@import '../../scss';

.container {
  @include containerHeight;
  @include flexCenter;
  .resetPassword {
    width: 100%;
    &_input {
      width: 340px;
      margin: auto;
      &_forgot {
        @include loginText;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .container {
    .resetPassword {
      padding: 2rem;
      &_input {
        width: 100%;
      }
    }
  }
}
