@import '../../scss';

.radioButton {
  display: block;
  position: relative;
  cursor: pointer;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;

  & input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
  }

  // &:hover input ~ .checkmark {
  //   background-color: #ccc;
  // }
  &.blue input:checked ~ &_custom {
    @include bgGradientBlue(333deg);
  }

  & input:checked ~ &_custom {
    @include bgGradientYellow(333deg);
  }

  & input:checked ~ &_custom:after {
    display: block;
  }

  & .content {
    padding: 0 2rem;
    @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);
    color: $color-black-primary;
  }

  &_custom {
    position: absolute;
    top: 50%;
    right: 2rem;
    height: 17px;
    width: 17px;
    border: 1px solid $color-grey-primary;
    background-color: $color-white-primary;
    border-radius: 50%;
    transform: translate(0%, -50%);
    &.leftContent {
      left: 0rem;
    }
  }
}
