@import '../../scss';

.contentDivider {
  @include text('Roboto', 2.4rem, normal, normal, normal, normal, -0.7px);
  color: $color-grey-primary;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1.5rem 0;

  &.line {
    width: 95%;
    height: 1px;
    margin: 2.8rem 0 2.8rem 2.5%;
    background-color: $color-grey-secondary;
  }

  &_line {
    height: 1px;
    flex: 0.4;
    background-color: $color-grey-secondary;
  }
  &_content {
    flex: 0.15;
    text-align: center;
  }
}
