@import '../../scss';

:local(.root) {
  box-sizing: border-box;
  color: $color-grey-primary;
  font-size: 1.4em;
  font-family: Roboto, sans-serif;
  display: flex;
  flex-flow: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 76px);
  padding: 76px + 20px 20px 200px;

  & > h1 {
    margin: 0 0 40px;
  }
}

:local(.schoolSelector) {
  width: 340px;
  max-width: 100%;
}

:local(.buttons) {
  & > button {
    margin: 0 5px;
  }
}

@media only screen and (max-width: 768px) {
  :local(.root) {
    margin-top: 56px;
    height: calc(100vh - 56px);
  }
}
