@import '../../scss';

.toggle {
  display: inline-block;
  margin: 5px 0;
  position: relative;
  height: 22px;
  width: 56px;
  &__checkbox {
    position: absolute;
    visibility: hidden;
    &:disabled ~ label {
      cursor: not-allowed;
    }
    &:checked ~ label {
      &:after {
        left: 35px;
      }
      &:before {
        background: $color-white-primary;
      }
    }
  }

  label {
    cursor: pointer;
    position: relative;
    line-height: 1.5;
    display: block;

    &:before,
    &:after {
      content: '';
      position: absolute;
      transition: all 0.3s;
    }

    &:before {
      vertical-align: middle;
      width: 56px;
      height: 22px;
      border-radius: 11.5px;
      box-shadow: 0 2px 8px 0 $color-black-rgba-secondary;
      background-color: $color-white-primary;
    }

    &.blue.on:after {
      @include bgGradientBlue(333deg);
    }

    &.on:after {
      @include bgGradientYellow(333deg);
    }

    &.off:after {
      @include bgGradientBlack(329deg);
    }

    &:after {
      content: '';
      width: 18px;
      height: 18px;
      top: 2px;
      left: 2px;
      border-radius: 50%;
    }
  }

  &_text {
    @include text('Roboto', 0.9rem, 500, normal, normal, normal, normal);
    position: absolute;
    top: 6px;
    color: $color-grey-primary;
    &.off {
      left: 29px;
    }
    &.on {
      left: 12px;
    }
  }
}
