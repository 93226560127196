@import '../../scss';

.accordion {
  &_content {
    &__header {
      cursor: pointer;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 2rem;
      border-bottom: solid 1px $color-black-secondary;
      &_label {
        @include text('Roboto', 2.8rem, normal, normal, normal, normal, normal);
        color: $color-grey-primary;
        max-width: 350px;
        text-overflow: ellipsis;
        overflow: hidden;
      }
      &_icon {
        display: flex;

        &_label {
          @include text('Roboto', 1rem, normal, normal, normal, 1.6, normal);
          text-align: right;
          margin-right: 1rem;
          color: $color-grey-primary;
          &_sub {
            font-family: 'Roboto', sans-serif;
            font-weight: 500;
            font-size: 1.4rem;
            font-weight: 500;
            line-height: 1.43;
          }
        }

        &__setting {
          padding: 1rem;
          border-radius: 25px;
          margin-right: 1rem;
          background-color: $color-lightgrey-primary;
        }

        &__minus {
          width: 36px;
          height: 36px;
          background-color: $color-lightgrey-primary;
          position: relative;
          border-radius: 25px;
          &::after {
            display: block;
            content: '';
            width: 16px;
            height: 2px;
            background-color: $color-grey-secondary;
            @include absCenter;
          }
        }
        &__plus {
          width: 36px;
          height: 36px;
          background-color: $color-lightgrey-primary;
          position: relative;
          border-radius: 25px;
          &::after {
            display: block;
            content: '+';
            font-size: 30px;
            font-weight: bold;
            color: $color-grey-secondary;
            @include absCenter;
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .accordion {
    &_content {
      &__header {
        &_label {
          @include text(
            'Roboto',
            1.8rem,
            normal,
            normal,
            normal,
            normal,
            normal
          );
          color: $color-grey-primary;
          max-width: 160px;
        }
        &_icon {
          &_label {
            &_sub {
              padding-top: 4px;
              @include text(
                'Roboto',
                1.4rem,
                normal,
                normal,
                normal,
                1.43,
                normal
              );
            }
          }

          &__setting {
            padding: 0.7rem;
          }

          &__minus {
            width: 29px;
            height: 29px;
            &::after {
              width: 13px;
              height: 1.5px;
            }
          }
          &__plus {
            width: 29px;
            height: 29px;
            &::after {
              font-weight: normal;
              font-size: 26px;
            }
          }
        }
      }
    }
  }
}
