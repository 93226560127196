@import '../../scss';

.home_container {
  @include containerHeight;
  @include flexCenter;
  align-items: flex-start;
  width: 100%;
  .home {
    width: 40%;
    padding: 5rem 0;
    margin: auto;
    &__accordion {
      width: 100%;
      margin: 3rem 0;
      border-radius: 8px;
      box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.03);
      border: solid 1px $color-black-secondary;
      background-color: $color-white-primary;
    }
    &__button {
      margin: auto;
      width: 100px;
    }
  }
}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .home_container {
    .home {
      width: 70%;
    }
  }
}
@media only screen and (max-width: 768px) {
  .home_container {
    .home {
      width: 90%;
      padding: 3rem 0;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .home_container {
    padding: 8rem 0;
    .home {
      margin: 0 !important;
    }
  }
}
