@import '../../scss';

.signupdiet {
  &_description {
    color: $color-greyish-brown-two;
    font-family: 'Open Sans';
    text-align: left;
    font-weight: normal;
    line-height: normal;
  }
  &_input {
    width: 60%;
    margin: 2rem auto;
    &_radioitem {
      padding: 2rem 4rem 2rem 2rem;
      margin: 3rem 0;
      background: $color-lightgrey-primary;
      border-radius: 6px;
      &_title {
        @include text('Roboto', 1.6rem, 500, normal, normal, normal, normal);
        padding: 0 0 0.5rem 0;
        color: $color-grey-primary;
      }
      &_des {
        @include text('Roboto', 1.4rem, 500, normal, normal, 1.29, normal);
        color: $color-grey-secondary;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .signupdiet {
    &_input {
      width: 100%;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .signupdiet {
    &_input {
      margin: 2.5rem auto;
    }
  }
}
