@import '../../scss';

.reportcardlost_container {
  @include containerHeight;
  @include flexCenter;
  .reportcardlost {
    &__textarea {
      padding: 3rem 0 0 0;
      width: 300px;
      margin: auto;
    }
    &__button {
      width: 380px;
      margin: 1rem auto;
      @include flexCenter;
      &_btn {
        width: 180px;
        &:not(:last-child) {
          margin-right: 20px;
        }
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .reportcardlost_container {
    .reportcardlost {
      width: 90%;
      margin: auto;
      &__button {
        width: 100%;
      }
    }
  }
}
