@import '../../scss';

.react-datepicker-wrapper {
  display: inline-block;
  position: absolute;
  bottom: 9px;
  input {
    border: none;
    width: 93%;
    @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);
    color: $color-grey-secondary;
  }
}
.react-datepicker {
  width: 100%;
  font-family: 'Roboto', sans-serif;
  font-size: 1.2rem;
  box-shadow: 0 0 10px 3px $color-lightgrey-primary;
  border: none;
}

.react-datepicker-popper {
  top: 4px !important;
  left: -10px !important;
  width: 142%;
}

.react-datepicker__month-container {
  width: 100%;
}

.react-datepicker__day--keyboard-selected,
.react-datepicker__month-text--keyboard-selected {
  background-color: none;
  @include bgGradientYellow(300deg);
}
.react-datepicker__day--selected {
  background-color: none;
  @include bgGradientYellow(300deg);
}
.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  margin: 0.5rem 0;
  width: 12%;
}

.react-datepicker__day-names {
  padding-top: 0.5rem;
}
.react-datepicker__triangle {
  display: none;
}
.react-datepicker__header {
  border-bottom: none;
  background-color: $color-lightgrey-primary;
}
.datepicker {
  position: relative;
  height: 48px;
  margin: 20px 0px;
  border-bottom: 2px solid $color-grey-secondary-rgba;
  @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);
  padding: 0 8px;

  &.active {
    border-bottom: 2px solid $color-yellow-primary;
    .datepicker-label {
      transform: translate(0, 1px) scale(0.75);
      opacity: 1;
      color: $color-yellow-one;
      font-size: 1.6rem;
    }
  }

  &.error {
    border-bottom: 2px solid $color-red-primary;
    .datepicker-label {
      transform: translate(0, 1px) scale(0.75);
      opacity: 1;
      color: $color-red-primary;
      font-size: 1.6rem;
    }
  }

  &.hasvalue .datepicker-label {
    transform: translate(0, 1px) scale(0.75);
    opacity: 1;
    color: $color-grey-secondary;
  }

  &_icon {
    position: absolute;
    right: 20px;
    top: 41%;
  }
}

.datepicker-label {
  font-size: 1.6rem;
  position: absolute;
  transform-origin: top left;
  transform: translate(0, 16px) scale(1);
  transition: all 0.1s ease-in-out;
  color: $color-grey-secondary;
  z-index: 2;
}

@media only screen and (max-width: 768px) {
  .react-datepicker-popper {
    width: 100%;
  }
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker__time-name {
    width: 13%;
  }
}
