@import '../../scss';

.home_childrenlist {
  &_item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1.5rem 2rem;
    border-bottom: 1px solid $color-black-secondary;
    &_label {
      @include text('Roboto', 1rem, normal, normal, normal, 1.6, normal);
      color: $color-grey-primary;
      font-size: 1.4rem;
      &_sub {
        font-family: 'Roboto', sans-serif;
        font-weight: 500;
        font-size: 1.4rem;
        font-weight: 500;
        line-height: 1.43;
      }
    }
  }
}
