@import '../../scss';

.signup_success {
  width: 50%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  &_image {
    &_desktop {
      display: block;
      margin: auto;
    }
    &_mobile {
      display: none;
    }
  }
  &_text {
    padding: 2rem 0;
  }
  &_button {
    width: 280px;
    margin: auto auto 1rem auto;
  }
}

@media only screen and (max-width: 768px) {
  .signup_success {
    width: 90%;
    &_image {
      &_desktop {
        display: none;
      }
      &_mobile {
        display: block;
        margin: auto;
      }
    }
    &_button {
      width: 100%;
    }
  }
}
