@import '../../scss';

.alertsWrapper {
  position: fixed;
  top: 75px;
  left: 50%;
  transform: translate(-50%, 0);
  z-index: 9999;
}

.toast {
  @include text('Roboto', 1.5rem, normal, normal, normal, normal, normal);
  padding: 1rem 2rem;
  border: 1px solid transparent;
  border-radius: 2.5px;
  margin-bottom: 10px;

  &:hover {
    opacity: 0.5;
  }

  &.is-error {
    color: $alert-error-color;
    background-color: $alert-error-background;
    border-color: $alert-error-border;
  }

  &.is-warning {
    color: rgb(92, 63, 9);
    background-color: #ffd23d;
    border-color: #eeb03e;
  }

  &.is-success {
    color: $alert-success-color;
    background-color: $alert-success-background;
    border-color: $alert-success-border;
  }
}
