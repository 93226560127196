@import '../../scss';

.accounttopup_container {
  @include containerHeight;
  @include flexCenter;
}

@media only screen and (max-width: 768px) {
  .accounttopup_container {
  }
}
