@import '../../scss';

.customTabs {
  padding: 0 0 3rem 0;
  &_list {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    &_item {
      height: 32px;
      width: 160px;
      margin: 0 0.2rem 0 0;
      cursor: pointer;
      position: relative;
      @include text('Roboto', 1.4rem, normal, normal, normal, normal, normal);
      text-align: center;
      text-transform: uppercase;
      color: $color-grey-secondary;
      background-color: $color-lightgrey-primary;
      &_text {
        width: 100%;
        @include absCenter;
      }
      &.blue.active {
        @include bgGradientBlue(171deg);
        color: $color-white-primary;
      }
      &.active {
        @include bgGradientYellow(307deg);
        color: $color-white-primary;
      }
      &:first-child {
        border-radius: 16.5px 0 0 16.5px;
      }
      &:last-child {
        border-radius: 0 16.5px 16.5px 0;
      }
    }
  }
}

@media only screen and (max-width: 768px) {
  .customTabs {
    width: 100%;
    &_list {
      width: 100%;
      &_item {
        width: 31.5%;
        font-size: 1.2rem;
      }
    }
  }
}

@media only screen and (max-width: 360px) {
  .customTabs {
    &_list {
      &_item {
        font-size: 1rem;
      }
    }
  }
}
