@import '../../scss';

.resetCheck {
  &_image {
    display: block;
    margin: auto;
    padding: 0 0 2rem 0;
  }
}

@media only screen and (max-width: 768px) {
  .resetCheck {
    &_image {
      display: none;
    }
  }
}
