@import '../../scss';

.orderStatus {
  @include flexCenter;
  flex-direction: column;
  margin: auto;
  padding: 5rem 0;
  flex: 1;
  width: 100%;
  &__image {
    position: relative;
    &_desktop {
      display: block;
      margin: auto;
    }
    &_mobile {
      display: none;
    }

    &_error {
      @include text('Roboto', 6.4rem, bold, normal, normal, normal, normal);
      @include absCenter;
      color: $color-red-ternary;
    }
  }
  &__text {
    padding: 2rem 0 0;
  }
  &__btn {
    width: 280px;
    margin: 3rem;
  }
}

@media only screen and (max-width: 768px) {
  .orderStatus {
    width: 100%;
    padding: 3rem 2rem;
    &__image {
      &_desktop {
        display: none;
      }
      &_mobile {
        display: block;
        margin: auto;
      }
    }
    &__card {
      width: 100%;
    }
    &__btn {
      width: 100%;
      margin: 3rem 0;
    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .orderStatus {
    height: 100%;
  }
}
