@import '../../scss';

.signup {
  &_title {
    width: 75%;
    margin: auto;
  }
  &_input {
    width: 340px;
    margin: auto;
    &_forgot {
      @include text('Roboto', 1.2rem, normal, normal, normal, normal, normal);
      text-align: center;
      color: $color-grey-secondary;
      margin: 1rem 0 0 0;
      cursor: pointer;
    }
    &_faculty {
      @include text('Roboto', 1.2rem, normal, normal, normal, normal, normal);
      padding: 2rem 0 0 0;
      color: $color-cerulean-primary;
      cursor: pointer;
    }
  }
}

@media only screen and (max-width: 768px) {
  .signup {
    &_title {
      width: 90%;
      margin: auto;
    }
    &_input {
      width: 100%;
    }
  }
}
