@import '../../scss';

.pieChart {
  &_label {
    @include text('Roboto', 1.6rem, normal, normal, normal, normal, normal);
    &_percent {
      font-size: 2.4rem;
    }
  }
}
