@import '../../scss';

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 76px;
  padding: 0 2rem;
  position: fixed;
  top: 0;
  width: 100%;
  background: $color-white-primary;
  z-index: 3;
  &_border {
    box-shadow: 0 4px 10px 0 $color-black-rgba-primary;
    border: solid 1px $color-black-secondary;
    background-color: $color-white-primary;
  }
  &_logo {
    cursor: pointer;
    height: 40px;
    &_icon {
      height: 100%;
    }
  }
  &_progress {
    width: 100%;
    position: absolute;
    top: 56px;
    padding: 10px;
    border-bottom: 1px solid $color-lightgrey-secondary;
    z-index: 2;
    background: $color-white-primary;
  }
}
@media only screen and (max-width: 768px) {
  .header {
    &_border {
      box-shadow: none;
    }
    border-bottom: 1px solid $color-lightgrey-secondary;
    height: 56px;
    &_logo {
      height: auto;
      &_icon {
        height: 28px;
      }
    }
  }
}
